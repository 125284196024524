import React, { useEffect, useState } from "react";
import "./home.scss";
import { Container, Nav, Navbar, Row, Col } from "react-bootstrap";
import roadmap from "../../assets/img/rm.png";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import Carousel from "../../components/Carousel/Carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Web3 from "web3";
import abi from "./abi.json";
import { Link } from "react-router-dom";

function Home() {
  const [web3, setWeb3] = useState("");
  const [add, setAdd] = useState("");
  const [instance, setInstance] = useState(null);
  const [mintNumber, setMintNumber] = useState(1);
  const [totalMinted, setTotalMinted] = useState(null);
  const [timerDays, setTimerDays] = useState();
  const [timerHours, setTimerHours] = useState();
  const [timerMinutes, setTimerMinutes] = useState();
  const [timerSeconds, setTimerSeconds] = useState();
  const [startMint, setStartMint] = useState(false);

  let interval;

  const startTimer = () => {
    const countDownDate = new Date("June 25,2022 1:00:00").getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();

      var d = new Date();
      var utc_offset = d.getTimezoneOffset();
      d.setMinutes(d.getMinutes() + utc_offset);

      const distance = countDownDate - d;

      const days = Math.floor(distance / (24 * 60 * 60 * 1000));
      const hours = Math.floor(
        (distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
      const seconds = Math.floor((distance % (60 * 1000)) / 1000);

      if (distance < 0) {
        // Stop Timer
        setStartMint(true);
        clearInterval(interval.current);
      } else {
        // Update Timer
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    });
  };

  useEffect(() => {
    let web3 = new Web3(Web3.givenProvider);
    setWeb3(web3);
    startTimer();

    const ins = new web3.eth.Contract(
      abi,
      "0x61370B984ac90e27f4b24a8823ebadc0E24C0032"
    );

    setInstance(ins);

    // const getTotalMinted = async () => {
    //   var tokenCount = await ins.methods.totalSupply().call();
    //   console.log(tokenCount);
    //   setTotalMinted(tokenCount);
    // };
    // getTotalMinted();
  }, []);

  async function requestAccount() {
    console.log("Requesting account...");

    // ❌ Check if Meta Mask Extension exists
    if (window.ethereum) {
      console.log("detected");

      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAdd(accounts[0]);
      } catch (error) {
        console.log("Error connecting...");
      }
    } else {
      window.open("https://metamask.app.link/dapp/koinnft.io/");
      alert("Meta Mask not detected");
    }
  }

  const mint = async () => {
    if (add) {
      if (instance) {
        console.log(
          await instance.methods.mint(mintNumber).send({
            from: add,
            value: (
              (await instance.methods.cost().call()) * mintNumber
            ).toString(),
          })
        );
      } else {
        console.log("error");
      }
    } else {
      alert("Please Connect your wallet");
    }
  };

  const plus = () => {
    if (mintNumber < 1000) setMintNumber(mintNumber + 1);
  };
  const minus = () => {
    if (mintNumber > 1) {
      setMintNumber(mintNumber - 1);
    }
  };

  return (
    <div>
      <div className="showcase">
        <div className="pt-2">
          <Container fluid>
            <Navbar className="cus-nav" variant="dark" expand="lg">
              <Navbar.Brand
                href="#top"
                className="font-weight-bold text-uppercase"
              >
                <img
                  className="img-fluid logo mr-3"
                  src={require("../../assets/img/klogo.png").default}
                  alt=""
                />
              </Navbar.Brand>
              <div className="">
                {/* <a
                  href="https://www.instagram.com/draconft/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="si"
                    src={require("../../assets/img/Instagram.png").default}
                    alt=""
                  />
                </a> */}
                <a
                  href="https://twitter.com/koinbnb22"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="si"
                    src={require("../../assets/img/Twitter.png").default}
                    alt=""
                  />
                </a>
                <a
                  href="https://discord.com/invite/jsKeJj7ta9"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="si"
                    src={require("../../assets/img/Discord.png").default}
                    alt=""
                  />
                </a>
                <a
                  href="https://opensea.io/collection/airborneapes"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="si"
                    src={require("../../assets/img/Opensea.png").default}
                    alt=""
                  />
                </a>
              </div>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                  <Nav.Link href="#about" className="link-style linkh">
                    About
                  </Nav.Link>
                  <Nav.Link href="#gallery" className="link-style linkh">
                    Gallery{" "}
                  </Nav.Link>
                  <Nav.Link href="#roadmap" className="link-style linkh">
                    Roadmap
                  </Nav.Link>
                  <Nav.Link href="#team" className="link-style linkh">
                    Team
                  </Nav.Link>
                  {/* <Nav.Link href="#game" className="link-style linkh">
                    Game
                  </Nav.Link> */}
                  <Nav.Link href="#faq" className="link-style linkh">
                    FAQ{" "}
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Container>
        </div>
        <div className="showcase-content">
          <Container className="d-flex align-items-center justify-content-center h-75">
            <div className="mintbox pb-5">
              <Container className="d-flex justify-content-center">
                <div className="mint">
                  <div className="">
                    <h1 className="mih1 text-center  text-uppercase mb-3">
                      Mint KOIN NFTs
                    </h1>

                    {startMint ? null : (
                      <Container
                        className="countd d-flex align-items-end justify-content-center h-50"
                        fluid
                      >
                        <Row>
                          <Col lg="12">
                            <div className="text-white">
                              <Row className="">
                                <Col>
                                  <Row>
                                    <Col>
                                      <h3 className="text-center">
                                        {timerDays}
                                      </h3>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-center">
                                        <h6 className="text-uppercase">Days</h6>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col>
                                  <Row>
                                    <Col>
                                      <h3 className="text-center">
                                        {timerHours}
                                      </h3>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-center">
                                        <h6 className="text-uppercase">
                                          Hours
                                        </h6>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col>
                                  <Row>
                                    <Col>
                                      <h3 className="text-center">
                                        {timerMinutes}
                                      </h3>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-center">
                                        <h6 className="text-uppercase">
                                          Minutes
                                        </h6>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col>
                                  <Row>
                                    <Col>
                                      <h3 className="text-center">
                                        {timerSeconds}
                                      </h3>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-center">
                                        <h6 className="text-uppercase">
                                          Seconds
                                        </h6>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    )}

                    <div className="text-center mt-4">
                      <button
                        onClick={requestAccount}
                        className="mintbutton btn  text-uppercase"
                      >
                        <span>
                          {add ? add.substring(0, 15) : "Connect Wallet"}
                        </span>
                      </button>
                    </div>
                    <div className="d-flex justify-content-center align-items-center mt-5">
                      <div className="mx-3">
                        <img
                          onClick={minus}
                          className="plus-img"
                          src={require("../../assets/img/4.png").default}
                          alt=""
                        />
                      </div>
                      <div className="display-number d-flex justify-content-center align-items-center">
                        <span className="number">{mintNumber}</span>
                      </div>
                      <div className="mx-3">
                        <img
                          onClick={plus}
                          className="plus-img"
                          src={require("../../assets/img/2.png").default}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="text-center mt-4">
                      <button
                        onClick={mint}
                        className="mintbutton btn  text-uppercase"
                      >
                        Mint Now
                      </button>
                    </div>
                    <div className="text-center mt-3">
                      <p className="text-center font-weight-bold subtitle">
                        Mint Price: 0.016 ETH
                      </p>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </Container>

          <Container
            className="h-25 mbcon d-flex align-items-end justify-content-center"
            fluid
          >
            <Row className="text-center w-100">
              <Col lg="4">
                <div className="">
                  <h1 className="tit">10,000</h1>
                  <h5 className="subtit">
                    10,000 unique randomly generated NFT
                  </h5>
                </div>
              </Col>
              <Col lg="4">
                <div className="">
                  <h1 className="tit">0.016 ETH</h1>
                  <h5 className="subtit">
                    Fair Launch, fair distribution: NFT cost 0.016 ETH
                  </h5>
                </div>
              </Col>
              <Col lg="4">
                <div className="">
                  <img
                    className="img-fluid showlogo"
                    src={require("../../assets/img/showlogo.png").default}
                    alt=""
                  />
                  <h5 className="subtit">
                    Ownership and commercial usage rights given to the holder
                    over their NFT
                  </h5>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div id="about" className="about">
        <Container className=" h-100">
          <Row className=" h-100">
            <Col
              lg="6"
              className="d-flex align-items-center justify-content-center rmt-3 "
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="aboutimg">
                <img
                  className="img-fluid about-img"
                  src={require("../../assets/img/10000.png").default}
                  alt=""
                />
              </div>
            </Col>
            <Col
              lg="6"
              className="d-flex align-items-center rmt"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="about-text">
                <h1 className="title text-uppercase">Once upon a time...</h1>
                <p className="mt-1 subtitle">
                  Collection of Airborne Apes from rookie to retired and some
                  rare Top Gun fighter pilots . No matter what life brings you
                  after the Air Force, you can take the ape out of the Air Force
                  but you can't take the force out of the Ape.
                </p>
                {/* <div className="text-center mt-2 ">
                  <button className="btn text-uppercase btn-cus font-weight-bold">
                    Mint your Own
                  </button>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <div className="slider text-center">
        <div className="slider-div m-0 p-0">
          <img className="slider-img" src={Slider1} alt="" />
          <img className="slider-img" src={Slider1} alt="" />
          <img className="slider-img" src={Slider1} alt="" />
          <img className="slider-img" src={Slider1} alt="" />
          <img className="slider-img" src={Slider1} alt="" />
        </div>
      </div> */}
      <div id="gallery" className="mx-5">
        <Container className="" fluid>
          <Row>
            <Col>
              <Carousel />
            </Col>
          </Row>
        </Container>
      </div>

      <div id="" className="dracoexp">
        <Container className="">
          <div className="text-center">
            <h1 data-aos="fade-up" data-aos-duration="2000" className="title">
              The KOIN Experience
            </h1>
          </div>
          <Row className="con-1 h-100">
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="4"
              className=""
            >
              <div className="">
                <div className="">
                  <div className="">
                    <img
                      className="img-fluid expicon"
                      src={require("../../assets/img/Exclusivity.png").default}
                      alt=""
                    />
                    <span className=" ml-3 exp-title">EXCLUSIVITY</span>
                  </div>
                  <div className="mt-2">
                    <span className="exp-subtitle">
                      Airbone Apes offers an exclusive 10,000 art piece
                      collection that includes extremely rare elements and
                      special graphics.
                    </span>
                  </div>
                </div>
                <div className="con-1">
                  <div className="">
                    <img
                      className="img-fluid expicon"
                      src={require("../../assets/img/Rewards 2.png").default}
                      alt=""
                    />
                    <span className=" ml-3 exp-title">REWARDS</span>
                  </div>
                  <div className="mt-2">
                    <span className="exp-subtitle">
                      As the project continues to grow, so too does the number
                      of exclusive access and rewards given to our community. We
                      will constantly offer prizes in multiple forms, such as
                      airdrops, sneak-peaks, merchandise, giveaway and much
                      more. Our goal is to constantly give back to our
                      supportive community through this evolution.
                    </span>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="4"
              className="rmt-3 d-flex align-items-center justify-content-center"
            >
              <div className="">
                {/* <video
                  height="300px"
                  src={require("../../assets/img/v1.mov").default}
                ></video> */}
                <img
                  className="img-fluid"
                  src={require("../../assets/img/coin.gif").default}
                  alt=""
                />
              </div>
            </Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="4"
              className="rmt-3"
            >
              <div className="">
                <div className="">
                  <div className="">
                    <img
                      className="img-fluid expicon"
                      src={require("../../assets/img/exp1.png").default}
                      alt=""
                    />
                    <span className=" ml-3 exp-title">TRANSPARENCY</span>
                  </div>
                  <div className="mt-2">
                    <span className="exp-subtitle">
                      We believe in complete ownership and fair distribution
                      within our clique. All the royalties collected will be
                      invested in the community and KOIN for unique perks and
                      future development!
                    </span>
                  </div>
                </div>
                <div className="con-1">
                  <div className="">
                    <img
                      className="img-fluid expicon"
                      src={require("../../assets/img/Community 3.png").default}
                      alt=""
                    />
                    <span className=" ml-3 exp-title">COMMUNITY</span>
                  </div>
                  <div className="mt-2">
                    <span className="exp-subtitle">
                      Become part of an amazing community that encourages love
                      for one another and the planet. As our member base expands
                      our goal is to give each and every one of our collectors a
                      more rewarding experience.
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="4"></Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="4"
              className="rmt-5"
            >
              <div className="">
                <div className="">
                  <div className="">
                    <img
                      className="img-fluid expicon"
                      src={require("../../assets/img/Future.png").default}
                      alt=""
                    />
                    <span className=" ml-3 exp-title">FUTURE</span>
                  </div>
                  <div className="mt-2">
                    <span className="exp-subtitle">
                      New collections with brand new characters will be designed
                      and crafted. As collections release, we envisioned and
                      planned the development of KOIN, a cryptocurrency tying
                      physical assets and providing holders with real
                      appreciating value.
                    </span>
                  </div>
                </div>
                {/* <div className="text-center mt-5">
                  <button className="w-50 btn text-uppercase btn-cus font-weight-bold">
                    Mint
                  </button>
                </div> */}
              </div>
            </Col>
          </Row>

          {/* <Row className="con-1">
            <Col
              className="rmt-5"
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="4"
            >
              <div className="">
                <div className="text-center">
                  <span className="p-title">PHASE 1</span>
                </div>
                <div className="text-center mt-3">
                  <span className="p-subtitle">
                    Minting NFTs for team rewards, giveaways and promotional
                    goals
                  </span>
                </div>
              </div>
            </Col>
            <Col
              className="rmt-5"
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="4"
            >
              <div className="">
                <div className="text-center">
                  <span className="p-title">PHASE 2</span>
                </div>
                <div className="text-center mt-3">
                  <span className="p-subtitle">
                    Limited Early-access Sales for whitelisted addresses
                  </span>
                </div>
              </div>
            </Col>
            <Col
              className="rmt-5"
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="4"
            >
              <div className="">
                <div className="text-center">
                  <span className="p-title">PHASE 3</span>
                </div>
                <div className="text-center mt-3">
                  <span className="p-subtitle">Sales open to the public</span>
                </div>
              </div>
            </Col>
          </Row>
      
       */}
        </Container>
      </div>

      <div id="roadmap" className="roadmap">
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          className="text-center"
        >
          {/* <img
            data-aos="fade-up"
            data-aos-duration="2000"
            className="img-fluid road"
            src={require("../../assets/img/500,000.png").default}
            alt=""
          /> */}

          <h1 className="text-center mt-5 title text-uppercase">Roadmap</h1>
        </div>
        <div className="mt-5">
          <img className="img-fluid" src={roadmap} alt="" />
        </div>
        {/* <Container className="mt-5">
          <Row>
            <Col data-aos="fade-up" data-aos-duration="2000" lg="6">
              <div className="border p-4 text-center">
                <h1 className="title">#1</h1>
                <div className="">
                  <ul className="ull">
                    <li>MINTING SITE</li>
                    <li>FREE MINT</li>
                    <li>DISCORD/ TWITTER</li>
                    <li>FINISH WEBSITE</li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col data-aos="fade-up" data-aos-duration="2000" lg="6">
              <div className="border p-4 text-center">
                <h1 className="title">#2</h1>
                <div className="">
                  <ul className="ull">
                    <li>MINT SITE OPEN FOR ARTISTS</li>
                    <li>COLLAB WITH OTHER PROJECTS</li>
                    <li>START WORK ON STAKING NFT</li>
                    <li>START WORK ON MAIN KOIN PROJECT</li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col data-aos="fade-up" data-aos-duration="2000" lg="6">
              <div className="border p-4 text-center">
                <h1 className="title">#3</h1>
                <div className="">
                  <ul className="ull">
                    <li>REVEAL KOIN STAKING UTILIES</li>
                    <li>RELEASE VIDEO ON KOIN MAIN PROJECT</li>
                    <li>REVEAL MORE PARTNERSHIPS WITH OTHER ARTISTS</li>
                    <li>
                      REVEAL PAWN SHOP NFT IDEA FOR CAPITAL WITH STAGNANT NFT’S
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col data-aos="fade-up" data-aos-duration="2000" lg="6">
              <div className="border p-4 text-center">
                <h1 className="title">#4</h1>
                <div className="">
                  <ul className="ull">
                    <li>REALEASE KOIN GAME FOR STAKING</li>
                    <li>EXPAND STAKING FOR EVEN HIGHER CAPITAL</li>
                    <li>INTRODUCE NEW NFT (KING KOIN- FOR GROUP BUYING)</li>
                    <li>RELEASE KOIN APP TO THE WORLD</li>
                    <li>&nbsp;</li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col data-aos="fade-up" data-aos-duration="2000" lg="6">
              <div className="border p-4 text-center">
                <h1 className="title">#5 +</h1>
                <div className="">
                  <ul className="ull">
                    <li>TBA</li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
     */}
      </div>

      <div id="team" className="team">
        <Container className=" h-100">
          <div className="text-center">
            <img
              data-aos="fade-up"
              data-aos-duration="2000"
              className="img-fluid"
              src={require("../../assets/img/teamtx.png").default}
              alt=""
            />
          </div>
          <Row className="h-100 mt-h">
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="6"
              className="d-flex justify-content-center"
            >
              <div className="">
                <div className="">
                  <img
                    className="img-fluid team-img"
                    src={require("../../assets/img/t1.png").default}
                    alt=""
                  />
                </div>
                <div className="mt-3 d-flex justify-content-around">
                  <p className="font-weight-bold">T-John (Founder)</p>
                  {/* <a
                    href="https://www.instagram.com/mattia.gastaldello/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img-fluid social-icon"
                      src={require("../../assets/img/Instagram.png").default}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://opensea.io/Fabbrogas"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img-fluid social-icon"
                      src={require("../../assets/img/Opensea.png").default}
                      alt=""
                    />
                  </a> */}
                </div>
              </div>
            </Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="6"
              className="d-flex justify-content-center rmt"
            >
              <div className="">
                <div className="">
                  <img
                    className="img-fluid team-img"
                    src={require("../../assets/img/t2.png").default}
                    alt=""
                  />
                </div>
                <div className="mt-3 d-flex justify-content-around">
                  <p className="font-weight-bold">T-Gunner (Developer)</p>
                  {/* <a
                    href="https://www.instagram.com/il_pegola/ "
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img-fluid social-icon"
                      src={require("../../assets/img/Instagram.png").default}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://opensea.io/il_pegola"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img-fluid social-icon"
                      src={require("../../assets/img/Opensea.png").default}
                      alt=""
                    />
                  </a> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <div id="game" className="gsec text-center">
        <Container className="">
          <div className="text-center">
            <img
              className="img-fluid"
              src={require("../../assets/img/k2.jpeg").default}
              alt=""
              srcset=""
            />
            <img
              className="img-fluid"
              src={require("../../assets/img/k1.jpeg").default}
              alt=""
              srcset=""
            />
          </div>
        </Container>
      </div> */}

      <div id="faq" className="faq text-center">
        <Container>
          <div className="text-center">
            <img
              data-aos="fade-up"
              data-aos-duration="2000"
              className="img-fluid"
              src={require("../../assets/img/FAQ.png").default}
              alt=""
            />
          </div>
          <Row>
            <Col lg="12">
              <Accordion>
                <AccordionItem data-aos="fade-up" data-aos-duration="2000">
                  <AccordionItemHeading>
                    <AccordionItemButton className="con-bot text-center">
                      What is Maximum Thrust Airborne Apes?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      {" "}
                      "Maximum Thrust" collection Airborne Ape Series. are
                      10,000 exclusive art piece collection of Air Force apes.
                      These Apes are reporting for duty, from retired apes with
                      day jobs to our rare top gun fighter pilots, proves you
                      can take the ape out of the Air Force but you can never
                      take the Force out of the ape.{" "}
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem data-aos="fade-up" data-aos-duration="2000">
                  <AccordionItemHeading>
                    <AccordionItemButton className="con-bot text-center">
                      How many Airbone Apes can I mint?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>There is no limit to how many you can mint.</p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem data-aos="fade-up" data-aos-duration="2000">
                  <AccordionItemHeading>
                    <AccordionItemButton className="con-bot text-center">
                      What is the secondary market structure for Airbone Apes?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      The amount of royalties was fixed at 8% to finance the
                      KOIN projects. We have the ambition to organize multiple
                      events around the world in order to strengthen the
                      community and build a network of entrepreneurs and
                      investors with the same mindset and common interests. The
                      funds collected will benefit the holders of KOIN.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem data-aos="fade-up" data-aos-duration="2000">
                  <AccordionItemHeading>
                    <AccordionItemButton className="con-bot text-center">
                      What is KOIN?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      KOIN is a cryptocurrency that is built on the Binance
                      Smart Chain used to transact and record assets onto the
                      blockchain.
                    </p>
                    <p>
                      KOIN uses the consensus model. This model that this smart
                      chain uses is delegated proof of stake.
                    </p>
                    <p>
                      The top 21 people staking the most KOINs get to decide
                      which transactions get approved and who gets blocked. If
                      you do not want to be the top 21 people holding large
                      amount of dollars in KOIN, you can actually earn by
                      staking rewards without owning that much KOIN at all. You
                      have to delegate your KOINs to the top validator and
                      they’ll share their rewards with you.
                    </p>
                    <p>
                      This method is quite centralized but it does allow for
                      cheaper fees and more transactions per second.
                    </p>
                    <p>
                      <iframe
                        width="300"
                        height="200"
                        src="https://www.youtube.com/embed/-RtDOBb1CIM"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem data-aos="fade-up" data-aos-duration="2000">
                  <AccordionItemHeading>
                    <AccordionItemButton className="con-bot text-center">
                      How users will use KOIN in every day transactions?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      User registers with KOIN, installs wallet integration and
                      upload phone documentation to the website. User submits
                      photos of phone, accessories, and features.
                    </p>
                    <p>
                      The website generates a value of the asset and converts
                      that value to a number of KOINs to disburse. Users can
                      stake KOIN, trade, or register assets for more KOINs.
                    </p>
                    <p>
                      Users will have the option to choose the fractional
                      percentage value of their phone asset they would like to
                      tie to KOIN. For example, they can choose to have 70% of
                      the asset’s value be tied to KOIN. If the phone is worth
                      $1,000, then they would purchase the value of 70% of
                      $1,000 or $700 worth of KOIN. The user accepts the
                      designated value and is transferred a number of KOINs and
                      the transaction is recorded on the blockchain.
                    </p>
                    <p>
                      After some time, the user’s phone contract is about to
                      expire with the carrier and the user decides to sell the
                      phone asset. They have the option to trade it back in with
                      Verizon and keep the KOINs they have or they can sell it
                      to a third-party. Selling it to a third party allows for
                      greater profit and appeal as now their phone asset has
                      KOIN attached to it allowing for their depreciating phone
                      asset to have an appreciating aspect to it. Users can
                      choose on the percentage scale the option to attach more
                      KOIN to the asset to make it more attractive to buyers.
                    </p>
                    <p>
                      The goal and premise of KOIN is to develop an appreciative
                      asset tied to physical items to allow for a stronger
                      selling appeal when a user wants to list their physical
                      items on a marketplace.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="footer mt-5 mb-5">
        <Container>
          <div className="d-flex justify-content-center">
            <div className="">
              <img
                className="img-fluid flogo"
                src={require("../../assets/img/klogo.png").default}
                alt=""
              />
            </div>
          </div>
          <div className="text-center mt-4">
            <p>
              <span className="font-weight-bold">
                © {new Date().getFullYear()} KOIN | Original. All rights
                reserved.
              </span>
            </p>
          </div>
          {/* <div className="">
            <p>
              *Disclaimer: The amount shown is an indicative sum. The amount is
              a variable sum based on the milestone reached and the Ethereum
              market value and it's subject to change at the sole discretion of
              the owners.
            </p>
          </div> */}
        </Container>
      </div>
    </div>
  );
}

export default Home;
